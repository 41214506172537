<template>
  <b-modal id="beneficio" title="Beneficio" size="md" variant="dark" :visible="showModal" :hide-footer="true"
    @hide="hideModal">
    <div v-if="beneficio">
      <div>
        <div>
          <p>Você escolheu o beneficio <b>{{ beneficio.nome }}</b></p>
        </div>
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(aplicarDesconto)">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Data em que o benefício <b>será utilizado:</b></label>
                  <ValidationProvider name="Data uso beneficio" rules="required|data_hoje" v-slot="{ errors, classes }">
                    <input type="tel" class="form-control shadow-none" v-model="dataUsoBeneficio" :class="classes"
                      v-mask="'##/##/####'" />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <hr class="mt-3 mb-3" />
            <div class="row">
              <div class="col-6">
                <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
                  <span v-if="loading">Beneficiando...</span>
                  <span v-else>Usar beneficio</span>
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-danger btn-block" @click.prevent="hideModal()" :disabled="loading">
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import auth from "../../auth";
import config from "../../config";
import axios from "axios";
import captureError from "../../helpers/captureError";
import moment from "moment";
export default {
  props: ["beneficio", "showModal"],
  components: {},
  data() {
    return {
      dataUsoBeneficio: undefined,
      modificou: false,
      loading: false,
    };
  },

  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.dataUsoBeneficio = undefined;
    },

    async aplicarDesconto() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente aplicar o uso deste beneficio?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        let dataFim = undefined;
        if (this.dataUsoBeneficio) {
          dataFim = moment(this.dataUsoBeneficio, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        }

        const data = {
          idAssinaturaRegistro: this.beneficio.idAssinaturaRegistro,
          idRegistro: this.beneficio.idRegistro,
          idUsuarioAdm: userInfo.id,
          dataUtilizacao: dataFim,
          idBeneficioPlano: this.beneficio.id
        };

        await axios.post(
          `${config.API_URLV2}/utilizacaoBeneficioAssinatura/`,
          data
        );

        this.loading = false;

        this.$toast.success("Beneficio aplicado com sucesso ✅");

        this.modificou = true;

        this.hideModal();
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel migrar o seguro");
      }
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #4FA935 !important;
}

.card-mensagem {
  cursor: pointer;
  text-align: center;
  color: #FFFFFF;
  padding: 24px;
  background-color: #78849E;
  border-radius: 12px;
}
</style>